<template>
  <div class="home">
    <!--    最外层-->
    <el-container>
      <!--      顶栏-->
      <el-header style="width: 1600px;margin: 0 auto">
        <el-row gutter="20">
          <!--左上角logo-->
          <el-col span="4">
            <img src="https://stc.qqij.cn/view.php/4a182a8b2071e0e53d65e09b98971111.png" width="200">
          </el-col>
          <!--导航栏-->
          <el-col span="14">
            <el-menu
                router
                :default-active="$router.currentRoute.path"
                class="el-menu-vertical-demo"
                background-color="white"
                text-color="black"
                mode="horizontal">
              <el-menu-item index="/homeIndex">首页</el-menu-item>
              <el-menu-item ><a href="#service" style="text-decoration: none">服务项目</a></el-menu-item>
              <el-menu-item index="/serviceProcesses">服务流程</el-menu-item>
              <el-menu-item index="/placeYourOrderOnline">在线下单</el-menu-item>
              <!--                        <el-menu-item index="5"><a href="">常见疾病</a></el-menu-item>-->
              <el-menu-item index="/HospitalIntroduce">医院列表</el-menu-item>
              <!--                        <el-menu-item index="8"><a href="#niceperson">优秀员工</a></el-menu-item>-->
              <el-menu-item index="/order">订单管理</el-menu-item>
              <el-menu-item index="/staff">全体员工</el-menu-item>
              <el-menu-item ><a href="#call" style="text-decoration: none">联系我们</a></el-menu-item>
            </el-menu>
          </el-col>
          <!--用户登录头像-->
          <el-col :span="6">
            <el-popover v-if="loginInfo==null"
                placement="top-start"
                title="欢迎您!"
                width="160"
                trigger="hover">
              <!--设置初始显示的内容-->
              <div slot="reference">
                <i style="font-size: 30px;position: relative;top: 20px" class="el-icon-user"></i>
              </div>
              <!--设置弹出的内容-->

              <el-button type="info"><router-link to="/reg" style="text-decoration: none">注册</router-link ></el-button>
              <el-button type="warning"><router-link to="/login" style="text-decoration: none">登录</router-link ></el-button>
            </el-popover>

            <el-popover v-else
                        placement="top-start"
                        title="欢迎您!"
                        width="160"
                        trigger="hover">
              <!--设置初始显示的内容-->
              <div slot="reference">
                <i style="font-size: 30px;position: relative;top: 20px" class="el-icon-user"></i>
              </div>
              <!--设置弹出的内容-->
              <div style="text-align: center">
                <el-avatar :src="loginInfo.avatar"></el-avatar><br>
                <a href=""><router-link to="/personal/info" style="text-decoration: none">个人中心</router-link ></a><br>
                <a href="javascript:void(0)" @click="logout()">退出登录</a><br>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </el-header>

        <!--        右侧主体-->
        <el-main class="aa">
          <div class="body">
            <!-- 页面的主体部分将由其它视图组件来显示 -->
            <router-view/>

<!--            <div style="border: 10px solid #3a4856 "></div>-->
          </div>

        </el-main>

      <el-footer style="width: 1600px;margin: 0 auto">
        <div class="cz-content" style="margin-top: 80px">
          <!--footer上部分-->
          <div class="cz-footer-content-top">
            <div class="cz-footer-content-top-left">
              <ul><h3><a href="">服务项目</a></h3>
                <li><a href="">陪同就诊</a></li>
                <li><a href="">陪伴护理</a></li>
                <li><a href="">跑腿代办</a></li>
                <li><a href="">附加服务</a></li>
              </ul>
              <ul><h3><a href="">服务流程</a></h3>
                <li><a href="">在线下单</a></li>
                <li><a href="">智能派单</a></li>
                <li><a href="">医院陪诊</a></li>
                <li><a href="">服务完成</a></li>
                <li><a href="">评价反馈</a></li>
              </ul>
              <ul><h3><a href="online-order">下单入驻</a></h3>
                <li><a href="online-order">小程序下单</a></li>
                <li><a href="join-us?type=0">陪诊师入驻</a></li>
                <li><a href="join-us?type=1">人才招募</a></li>
              </ul>
              <ul><h3><a href="">动态资讯</a></h3>
                <li><a href="">医院介绍</a></li>
                <li><a href="">常见疾病</a></li>
              </ul>
              <ul><h3><a href="">联系我们</a></h3>
                <li><a href="">在线留言</a></li>
              </ul>
            </div>
            <div class="cz-footer-content-top-right">
              <div class="title-1">服务热线</div>
              <div class="tel-2"><a id="call" href="tel:17630075660">010-95590</a></div>
              <div class="text-3">公司地址</div>
              <div class="text-3" style="font-weight: bold;">北京市海淀区安心陪诊中心</div>
            </div>
          </div>
          <!--footer下部分-->
          <div class="cz-footer-content-bottom" style="font-weight: bold;text-align: center">
            <a target="_blank" href="https://www.peizhen.zone">www.peizhen.zone</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            北京市安心陪诊中心 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            © 2016 - 2023 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a target="_blank" href="https://beian.miit.gov.cn/"
               style="margin-left: 20px; text-decoration: none;">010-95590</a></div>
        </div>
      </el-footer>
      </el-container>
  </div>
</template>
<style>
/*.aa .body{border: 2px solid red;height: 500px}*/
body {margin: 0;}
.layout-header{background-color:#28292e;color: #fff }
.layout-header h1 { margin: 0; line-height: 60px; font-size: 20px;float: left; }
.layout-header .login-user { float: right; display: flex; margin-top: 12px; }
.layout-header .loginuser .welcome { line-height: 36px; }
.layout-body { position: absolute; top: 60px; bottom: 0; left: 0; right: 0; }
.layout-aside { background: #3a4856; }
.layout-aside{border: #011f0e solid 2px}
.layout-main { background: #ddd; }
.layout-main .body { background: #fff; padding: 30px 30px 10px 30px; }
.layout-main .copyright p { color: #666; font-size: 11px; text-align: center; }
.layout-aside i { color: #fff !important; }
.layout-aside .el-menu { border: 0; text-align: left}
.layout-aside .el-menu .el-menu-item{ border: 0; }
.el-menu-item.is-active { background: #405872 !important; }

/*---------------------------------------footer【上、下】两部分-----------------------------------------------------*/
/*----------------------------------------【left】--------------------------------------------------------------------*/
.cz-footer-content-top {
  overflow: hidden;
  padding-bottom: 30px;
}

.cz-footer-content-top-left ul {
  list-style: none;
  float: left;
  width: 8%;
  margin: 0;
  padding: 0;
  margin-right: 10px; /*设置每行之间的距离*/
  padding-left: 50px;
}

.cz-footer-content-top-left ul:last-child {
  margin-right: 0;
}

.cz-footer-content-top-left ul h3 {
  font-size: 16px;
  color: #26334c;
  font-weight: normal;
  border-bottom: 1px solid #d9dbde;
  padding-bottom: 20px;

}

.cz-footer-content-top-left ul h3 a {
  color: #26334c;
}

.cz-footer-content-top-left ul li {
  list-style: none;
  margin: 0;
  padding: 7px 0;
}

.cz-footer-content-top-left ul li a {
  font-size: 14px;
  color: #666e80;
}

.cz-footer-content-top-left ul li a:hover {
  color: #000000;
}

/*----------------------------------------【right】--------------------------------------------------------------------*/

.cz-footer-content-top-right {
  float: right;
  width: 20%;
  text-align: right;
  margin-right: 250px;
}

.cz-footer-content-top-right .title-1 {
  font-weight: bold;
  font-size: 14px;
  color: #666e80;
  padding: 10px 0;
}

.cz-footer-content-top-right .tel-2 {
  font-weight: bold;
  font-size: 30px;
  color: #26334c;
}

.cz-footer-content-top-right .text-3 {
  font-weight: bold;
  font-size: 14px;
  color: #666e80;
  padding: 10px 0;
  line-height: 24px;
}

.cz-footer-content-bottom {
  clear: both;
  font-size: 14px;
  color: #a1a7b2;
  border-top: 1px solid #d9dbde;
  padding: 30px 0;
  margin: 30px 30px 0px 30px;
  position: relative;
  overflow: hidden;
  font-family: 'Barlow-Regular', Arial, Microsoft Yahei;
  text-align: center;
}

.cz-footer-content-bottom a {
  font-size: 14px;
  color: #a1a7b2;
  font-family: 'Barlow-Regular', Arial, Microsoft Yahei;
}

.cz-footer-content-bottom {
  margin: 0px 0px 0px 0px;
}

</style>

<script>
import axios from "axios";
import TokenAxios from "@/http/TokenAxios";

export default {
  data() {
    return {
      loginInfo:localStorage.loginInfo?JSON.parse(localStorage.loginInfo):null,
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout(){
      console.log(JSON.parse(localStorage.loginInfo))
      if (confirm("您确认退出登录吗?")){
        TokenAxios.get('http://localhost:29081/passport/logout').then((response) => {
          console.log(JSON.parse(localStorage.loginInfo))
          let jsonResult = response.data;
          debugger
          if(jsonResult.state===20000){
            debugger
            localStorage.clear();
            this.$router.push('/login');
            }
        })
      }

    }
  }
}
</script>
