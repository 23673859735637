import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/homeIndex",
        name: 'home',
        component: HomeView,
        children: [
            {
                path: '/homeIndex',
                name: 'homeindex',
                component: () => import( '../views/user/homeIndex.vue')
            }, {
                path: '/serviceProcesses',
                name: 'serviceProcesses',
                component: () => import( '../views/user/serviceProcesses.vue')
            }, {
                path: '/placeYourOrderOnline',
                name: 'placeYourOrderOnline',
                component: () => import( '../views/user/placeYourOrderOnline.vue')
            }, {
                path: '/staff',
                name: 'staff',
                component: () => import( '../views/user/staff.vue')
            }, {
                path: '/HospitalIntroduce',
                name: 'HospitalIntroduce',
                component: () => import( '../views/user/HospitalIntroduce.vue')
            }, {
                path: '/order',
                name: 'order',
                component: () => import( '../views/user/order.vue')
            },{
                path: '/evaluate/:id',
                name: 'evaluate',
                component: () => import( '../views/user/evaluate.vue')
            },{
                path: '/personal',
                // redirect: '/personal/info',
                name: 'personal',
                component: () => import( '../views/user/personal.vue'),
                children:[{
                    path: '/personal/info',
                    name: 'info',
                    component: () => import( '../views/user/info.vue')
                },{
                    path: '/personal/clientAddNew',
                    name: 'client',
                    component: () => import( '../views/user/clientAddNew.vue')
                },{
                    path: '/personal/client',
                    name: 'client',
                    component: () => import( '../views/user/client.vue')
                }]
            }]
    }, {
        path: '/login',
        name: 'login',
        component: () => import( '../views/LoginView.vue')
    }, {
        path: '/reg',
        component: () => import( '../views/RegUser.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
