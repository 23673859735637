import axios from 'axios';
import qs from 'qs';

let instance = axios.create({
    'headers': {
        'Authorization': localStorage.getItem('loginInfo')!=null?JSON.parse(localStorage.getItem('loginInfo')).token:''
    }
});

const TokenAxios = {
    get(url, params) {
        return instance({
            method: 'GET',
            url: url,
            data: params
        });
    },
    post(url, params) {
        return instance({
            method: 'POST',
            url: url,
            data: qs.stringify(params)
        });
    },

    get2(url) {
        return instance({
            method: "GET",
            responseType: "blob",
            url: url
        })
    }
}

export default TokenAxios;
